.col3-box {
    &__section {
        margin-bottom: 75px;
        padding-left: 32px;
        padding-right: 32px;

        &__underline {
            height: 2px;
            border-radius: 4px;
            background-color: $color-underline;
        }

        &__title {
            margin: 34px 0 26px;
            height: 60px;
            overflow: hidden;
            color: #333333;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.43px;
        }

        &__content {
            color: #333333;
            font-family: $font-secondary;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }
    }
}

.col3-box-centered {
    margin-top: 100px;
    text-align: center;

    &__section {
        margin-bottom: 75px;

        &__underline {
            height: 2px;
            border-radius: 4px;
            background-color: $color-underline;
        }

        &__title {
            margin: 34px 0 26px;
            color: #333333;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.52px;
        }

        &__content {
            color: #333333;
            font-family: $font-secondary;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;

            &__value {
                margin-bottom: 30px;
                font-size: 32px;
                text-align: center;
                line-height: 48px;
                color: #26A9E0;
                font-weight: 600;
                span {
                    font-size:20px;
                    padding-left:8px
                }
            }

            &__subsidy {
                color: #8FC642;
                font-size: 32px;
                font-weight: 600;
                line-height: 48px;
                text-align: center;
                span {
                    font-size:20px;
                    padding-left:8px
                }
            }



        }
    }
}

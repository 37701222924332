//zmienne
//fonty
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Lato', sans-serif;
$font-counter: 'Muli' , sans-serif; //kolory
$color-primary: #119fdc;
$color-email: #178cd4;
$color-background: rgba(38,169,224,0.1);
$color-underline: rgba($color-primary, .2);
$color-footer: #fbfbfb; //
$jumbotron-height: calc(100vh - 72px);
$jumbotron-max-height: 732px;
$jumbotron-min-height: 630px;

body {
    font-family: $font-primary;
}

.button {
    margin: auto;
    width: 264px;
    height: 60px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 100px;
    background-color: $color-primary;
    text-align: center;

    &__icon {
        float: left;
        margin-top: 5px;
        margin-left: 5px;
        display: inline-block;
        width: 54px;
        height: 54px;
        background: url('../images/play-icon.svg') no-repeat center;
        background-size: 54px;
    }

    &:hover {
        background-color: lighten( #26a9e0, 10% );
        border-color: lighten( #26a9e0, 10% );
        transition: 0.2s ease-in;
        cursor: pointer;
    }

    &__text {
        display: inline-block;
        padding-right: 25px;
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.52px;
    }
}

.button-sidebar {
    margin-top: 90px;
    margin-bottom: 110px;
    margin-left: 20px;
    text-align: left;
    color: #0e74bb;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;

    a {
        color: #0e74bb;
    }

    &:hover {
        color: rgba(14, 116, 187, 0.7);

        a {
            color: rgba(14, 116, 187, 0.7);
        }
    }
}

.section-image {
    display: block;
    margin: auto;
    text-align: center;
    background-color: $color-background;
    padding-bottom: 75px;

    &__section {
        margin: auto;
    }

    img {
        margin: auto;
        display: block;
        width: 100%;
    }
}

.section-header {
    text-align: center;
    margin: 75px auto ;;

    &__title {
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
        letter-spacing: -0.62px;

        &--blue {
            color: $color-primary;
        }
    }

    &__subtitle {
        max-width: 900px;
        margin: auto;
        opacity: 0.7;
        color: #333333;
        font-family: $font-secondary;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;

        ul {
            li {
                color: #333333;

                &:before {
                    display: inline-block;
                    content: '\2022';
                    color: #8bc64f;
                    padding-right: 0.5em;
                }

                p {
                    display: inline;
                }
            }
        }
    }
}

.button-news {
    cursor: pointer;
    color: #26a9e0;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.14px;
    width: 217px;
    line-height: 46px;
    margin: 0 auto;
    height: 51px;
    border-radius: 24px;
    border: 2px solid #d1ecf8;

    &--margined {
        margin-top: 20px;
    }

    .loader {
        margin: 100px auto;
        font-size: 25px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        -webkit-animation: load5 1.1s infinite ease;
        animation: load5 1.1s infinite ease;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }
    @-webkit-keyframes load5 {
        0%,
        100% {
            box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
        }

        12.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0 #ffffff, 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
        }

        25% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7), 2.5em 0 0 0 #ffffff, 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        37.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5), 2.5em 0 0 0 rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0 #ffffff, 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        50% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        62.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5), 0 2.5em 0 0 rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        75% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        87.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5), -2.6em 0 0 0 rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0 #ffffff;
        }
    }
    @keyframes load5 {
        0%,
        100% {
            box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
        }

        12.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0 #ffffff, 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
        }

        25% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7), 2.5em 0 0 0 #ffffff, 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        37.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5), 2.5em 0 0 0 rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0 #ffffff, 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        50% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        62.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5), 0 2.5em 0 0 rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        75% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
        }

        87.5% {
            box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5), -2.6em 0 0 0 rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0 #ffffff;
        }
    }

    &:hover {
        background-color: #26a9e0;
        border-color: #26a9e0;
        transition: 0.1s ease-in;
        color: #ffffff;
    }
}

.white-titlebox {
    margin-top: 72px;
    height: 214px;
    background-color: #ffffff;
    padding: 54px 0;

    &__title {
        color: #0e74bb;
        text-align: center;
        font-size: 44px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -1.1px;
        margin-bottom: 17px;
    }

    &__description {
        color: #0e74bb;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
    }
}

.background-gradient {
    background-image: linear-gradient(to left, rgba(143, 198, 66, 0.1) 0%, rgba(100, 169, 223, 0.1) 100%);
}
// loader z: https://projects.lukehaas.me/css-loaders/

.loader {
    margin: 100px auto;
    font-size: 25px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
@-webkit-keyframes load5 {
    0%,
    100% {
        box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.5), -1.8em -1.8em 0 0 rgba(#119FDC, 0.7);
    }

    12.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.7), 1.8em -1.8em 0 0 #ffffff, 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.5);
    }

    25% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.5), 1.8em -1.8em 0 0 rgba(#119FDC, 0.7), 2.5em 0 0 0 #ffffff, 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    37.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.5), 2.5em 0 0 0 rgba(#119FDC, 0.7), 1.75em 1.75em 0 0 #ffffff, 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    50% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.5), 1.75em 1.75em 0 0 rgba(#119FDC, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    62.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.5), 0 2.5em 0 0 rgba(#119FDC, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    75% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.5), -1.8em 1.8em 0 0 rgba(#119FDC, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    87.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.5), -2.6em 0 0 0 rgba(#119FDC, 0.7), -1.8em -1.8em 0 0 #ffffff;
    }
}
@keyframes load5 {
    0%,
    100% {
        box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.5), -1.8em -1.8em 0 0 rgba(#119FDC, 0.7);
    }

    12.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.7), 1.8em -1.8em 0 0 #ffffff, 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.5);
    }

    25% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.5), 1.8em -1.8em 0 0 rgba(#119FDC, 0.7), 2.5em 0 0 0 #ffffff, 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    37.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.5), 2.5em 0 0 0 rgba(#119FDC, 0.7), 1.75em 1.75em 0 0 #ffffff, 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    50% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.5), 1.75em 1.75em 0 0 rgba(#119FDC, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(#119FDC, 0.2), -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    62.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.5), 0 2.5em 0 0 rgba(#119FDC, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(#119FDC, 0.2), -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    75% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.5), -1.8em 1.8em 0 0 rgba(#119FDC, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(#119FDC, 0.2);
    }

    87.5% {
        box-shadow: 0 -2.6em 0 0 rgba(#119FDC, 0.2), 1.8em -1.8em 0 0 rgba(#119FDC, 0.2), 2.5em 0 0 0 rgba(#119FDC, 0.2), 1.75em 1.75em 0 0 rgba(#119FDC, 0.2), 0 2.5em 0 0 rgba(#119FDC, 0.2), -1.8em 1.8em 0 0 rgba(#119FDC, 0.5), -2.6em 0 0 0 rgba(#119FDC, 0.7), -1.8em -1.8em 0 0 #ffffff;
    }
}

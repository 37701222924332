.video-container {
    position: fixed;
    top: 0;
    z-index: 9920;
    width: 100%;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.9);
    padding: 20px;

    &__popup {
        video {
            width: 100%;
            padding: 0;
            margin: 0;
        }
        max-width: 944px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        box-shadow: 0 2px 40px rgba(0, 0, 0, 0);
    }
}

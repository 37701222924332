.footer {
    min-height: 228px;
    margin-top: 65px;
    padding-top: 36px;
    border-radius: 2px;
    background-color: $color-footer;
    box-shadow: 0 2px 4px rgba(74, 74, 74, 0.2);

    &__section {
        padding: 32px 0 32px 32px;

        &__title {
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1.86px;
        }

        &__content {
            margin-top: 12px;
            color: #4a4a4a;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.3px;

            &--bold {
                font-weight: 600;
            }

            &--email {
                color: $color-email;
                text-decoration: none;

                &:hover {
                    color: $color-email;
                }
            }
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    min-height: 72px;
    background: #ffffff;

    &__distance {
        height: 72px;
    }

    &__left {
        float: left;
        position: relative;
        padding-right: 35px;

        &__logo {
            display: inline-block;
            width: 179px;
            height: 64px;
            margin-top: 3px;
            background: no-repeat center;
            background-size: contain;
        }

        &__logo-subsidy {
            margin-left: 20px;
            display: inline-block;
            width: 456px;
            height: 64px;
            background: url('../images/subsidy.png') no-repeat center;
            background-size: contain;
        }
    }

    &__right {
        float: right;

        &__menu {
            display: inline-block;
            float: left;

            &__container {
                background-color: #ffffff;

                &__item {
                    display: inline-block;
                    line-height: 72px;
                    opacity: 0.8;
                    color: #253139;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: -0.07px;
                    padding-left: 20px;

                    a:hover {
                        color: $color-primary;
                        transition: 0.1s ease-in;
                        cursor: pointer;
                    }
                }
            }
        }

        &__language {
            display: inline-block;
            float: right;

            &__container {
                margin: 17px 0;

                &__item {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px;
                    color: #535c62;

                    &.active {
                        border-radius: 6px;
                        border: 2px solid #d1ecf8;
                        color: #369bcf;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    &__distance {
        height: 72px;
    }
}

.menu-icon {
    display: none;
}
@include media-breakpoint-down(lg) {
    .header {
        &__left {
            &__logo-container {
                height: 72px;
                width: 200px;
                padding: 10px;
                box-shadow: none;
            }
        }

        &__right {
            float: none;

            &__menu {
                width: 100%;

                &__container {
                    position: fixed;
                    top: 72px;
                    left: 0;
                    right: 0;

                    &__item {
                        display: none;
                        padding-right: 0;
                        line-height: 50px;
                        text-align: center;
                        border-bottom: 1px solid #369bcf;
                    }
                }
            }

            &__language {
                display: none;
            }
        }
    }

    .menu-icon {
        display: block;
        width: 35px;
        height: 30px;
        margin: 20px 0 10px;
        border-radius: 5px;
        border: 0 solid #369bcf;
        position: absolute;
        right: 120px;
        top: -72px;

        span {
            height: 4px;
            width: 100%;
            position: absolute;
            left: 0;
            background: #369bcf;

            &:nth-child(1) {
                top: 5px;
            }

            &:nth-child(2) {
                top: 15px;
            }

            &:nth-child(3) {
                top: 25px;
            }
        }
    }

    .responsive {
        .header__right__menu__container__item {
            display: block;
        }
    }
}
@include media-breakpoint-down(md) {
    .menu-icon {
        right: 20px;
    }

    .header {
        &__left {
            &__logo-subsidy {
                margin-left: 10px;
            }
        }
    }
}
@include media-breakpoint-down(xs) {
    .header {
        height: 140px;

        &__left {
            background: #ffffff;

            &__logo-subsidy {
                width: 160%;
            }
        }
    }
}

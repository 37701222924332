.sidebar {
    margin-top: 86px;

    &__section {
        margin-bottom: 40px;
        min-height: 380px;

        &__underline {
            height: 27px;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
            opacity: 0.2;
            margin-bottom: 32px;
            background-color: #1b1f22;

            &--green {
                background-color: #8fc642;
            }

            &--blue {
                background-color: #26a9e0;
            }
        }

        &__date {
            padding: 0 20px;
            opacity: 0.5;
            color: #1b1f22;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 3.5px;
        }

        &__title {
            padding: 0 20px;
            margin: 22px 0 34px;
            color: #1b1f22;
            font-size: 21px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.53px;
        }

        &__content {
            padding: 0 20px;
            height: 145px;
            opacity: 0.8;
            color: #1b1f22;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            overflow: hidden;
        }
    }
}

.news-box {
    padding-top: 59px;
    padding-bottom: 80px;

    &__title {
        padding-bottom: 36px;
        float: left;
        color: #1b1f22;
        font-size: 28px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -0.7px;
    }

    &__button {
        line-height: 58px;
        float: right;
        color: #2b80c0;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.7px;
    }

    .loader {
        height: 10px;
        margin: 50px auto;
        font-size: 9px;
        width: 10px;
    }
}

.article {
    margin: 72px 0;

    &__title {
        margin: 0 0 28px;
        color: #1b1f22;
        font-size: 28px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: -0.7px;
    }

    &__image {
        margin: 40px 0;

        img {
            width: 100%;
        }
    }

    &__text {
        color: rgba(#51585c, 0.8);
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
        word-wrap: break-word;

        ul {
            list-style: disc;
            padding-left: 50px;
        }

        strong {
            font-weight: 700;
        }

        table {
            border: 1px solid;
            text-align: center;

            td {
                border: 1px solid;
                padding: 5px;
                vertical-align: middle;
            }
        }

        a {
            color: #2b80c0 !important;
        }

        img {
            max-width: 100%;
        }
    }
}
